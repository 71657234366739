@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 110px 0 52px 0;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }
}
