@use "../../../../../scss/colors";

@use "../../../../../scss/variables";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .formContainer {
    width: 464px;
    height: auto;
    border-radius: 10px;
    padding: 30px;
    margin: -10px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .heading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;

      span {
        color: colors.$yellow;
      }
    }

    .fieldItem {
      width: 100%;
      height: auto;
      
    }

    .authButton {
      width: 100%;
      border-radius: 10px;
      border: none;
      padding: 15px 0;
      background-color: colors.$yellow;
      color: colors.$black;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .bottomText {
      color: colors.$white;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: colors.$yellow;
      }
      .link {
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}

.lineContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  .line {
    width: 100%;
    height: 3px;
    background-color: #cecece;
    border-radius: 2px;
  }
}

.li {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}



.row {
  width: 100%;
  display: flex;
  color: colors.$black;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.link {
  color: colors.$black;
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;
}

.joinNowBtn {
  color: #fff75e;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  padding: 13px 0px;
  border-radius: 10px;
  border: 2px solid #fff75e;
  width: 100%;
  background-color: transparent;
  cursor: pointer;
}

.text {
  font-size: 14px;
  color: black;
  margin: 5px 0;
  padding: 0;
}

.google_btn {
  width: 20%;
  height: 40px;
  border-radius: 5px;
  border: none;
  outline: none;
  background-color: #f9f9f9;
  font-size: 16px;
  font-weight: 500;
  margin: 0 0 20px 0;
  color: #2c444e;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.google_btn > img{
  width: 30px;
  height: 30px;
  // padding-right: 20px;
  object-fit: cover;
}

.google_btn > span{
  margin-left: 10px;
}

.hr_lines:before{
  content:" ";
  display: block;
  height: 0.5px;
  width: 130px;
  position: absolute;
  top: 50%;
margin-left: 100px;
  left: 0;
  background: #483285;
}

.hr_lines{
  position: relative;
  max-width: 500px;
  margin: 10px auto;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 10px;
  font-family: Poppins;
  font-weight: 400;
  color: #483285;
}

.hr_lines:after{
  content:" ";
  height: 0.5px;
  width: 130px;
  background: #483285;
  display: block;
  position: absolute;
  top: 50%;
margin-right: 100px;
  right: 0;
}


@media only screen and (max-width: 768px) {
.heading {
  font-weight: 400 !important;
 }
}

@media only screen and (max-width: 400px) {

  .hr_lines:before{
    width: 60px !important;
  }
  
  .hr_lines:after{
    content:" ";
    width: 60px  !important;
  }
  
  

   
// .row {
  
//   display: flex;
//   color: colors.$black;
//   flex-direction: column;
// }
// .row .link{
//   margin-top: 15px;
// }

}




@media only screen and (max-width: 350px) {
.link {
  font-size: 9px;
}

}