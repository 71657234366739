@use "../../scss/colors";

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  .inputContainer {
    width: 100%;
    max-width: 458px;
    padding: 9px;
    box-sizing: inherit;
    border-radius: 5px;
    background-color: #f8f8f8;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      margin-left: 5px;
      width: 100%;
      border: none;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.27);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:focus {
        outline: none;
      }
    }
  }

  .headingFlex{
    display:flex ;
    justify-content: center;
  }

  .heading {
    color: colors.$black;
    font-family: Poppins;
    font-size: 38px;
    font-weight: 600;
    line-height: normal;
  }
  .subHeading{
    color: colors.$black;
    font-family: Poppins;
    font-size: 18px;
    font-weight: 400;
    line-height: normal;
  }

  .faqsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageUsContainer {
    width: 100%;
    padding: 10px 14px 22px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .headline {
      color: colors.$black;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .text {
      color: colors.$black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}



@media only screen and (max-width: 950px) {
  .img_container{
    width: 500px !important;
  }
  .img_container1{
    width: 500px !important;
  }
  .img_container2{
    width: 500px !important;
  }
  .img1{
    margin-right: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 868px) {
  .img_container{
    width: 450px !important;
  }
  .img_container1{
    width: 430px !important;
  }
  .img_container2{
    width: 430px !important;
  }
  .img1{
    margin-right: 0px !important;
    width: 100% !important;
  }
}
@media only screen and (max-width: 768px) {
  .img_container{
    width: 510px !important;
  }
  .img_container1{
    width: 470px !important;
  }
  .img_container2{
    width: 470px !important;
  }
.img1{
  margin-right: 0px !important;
  width: 100% !important;
}
}


@media only screen and (max-width: 600px) {
  .heading{
    text-align: center !important;
    font-size: 34px !important;
  }
  .img_container{
    width: 400px !important;
  }
  .img_container1{
    width: 390px !important;
  }
  .img_container2{
    width: 380px !important;
  }
  .img1{
    margin-right: 0px !important;
    width: 100% !important;
  }
}

@media only screen and (max-width: 450px) {
  .img_container{
    width: 260px !important;
  }
  .img_container1{
    width: 230px !important;
  }
  .img_container2{
    width: 230px !important;
  }
  .img1{
    margin-right: 0px !important;
    width: 100% !important;
  }
}

