@use "../../scss/colors";
@use "../../scss/variables";
@use "../../scss/z-indices";

@keyframes fade-in {
  from {
    opacity: 0;
  }
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(colors.$white, 0.75);
}

.modalPageContainer {
  position: absolute;
  z-index: z-indices.$modal;
}

.modalContainer {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: fadeIn 0.2s ease-out;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalPanel {
  margin-left: auto;
  margin-right: auto;
}

.card {
  box-sizing: border-box;
  border-radius: 10px;
  background-color: #F9F9F9;
  border: 1px solid rgba(255, 255, 255, 0.05);
  max-width: calc(
    100vw - variables.$width-size-menu - variables.$spacing-lg * 2
  );

  &.isPadding {
    padding: 16px 20px;
  }

  &.sm {
    width: variables.$width-modal-sm;
  }

  &.md {
    width: variables.$width-modal-md;
  }

  &.lg {
    width: variables.$width-modal-lg;
  }

  &.xl {
    width: variables.$width-modal-xl;
  }
}
