@use "../../../../scss/colors";

.text {
  color: colors.$black;
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
}

.container {
  width: 300px;
  min-height: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: colors.$white;
  padding: 12px 0;
  color: colors.$black;
  font-family: Poppins;
  font-size: 22px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  position: relative;
  border-top: 0.5px solid colors.$black;
  border-bottom: 0.5px solid colors.$black;

  .dotsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;

    &.left {
      left: -8px;
    }

    &.right {
      right: -8px;
    }
  }

  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: colors.$yellow;

    &.left {
      border-right: 0.5px solid colors.$black;
    }

    &.right {
      border-left: 0.5px solid colors.$black;
    }
  }
}

@media only screen and (max-width: 768px) {
  
  .container {
    background-color: colors.$yellow !important;
  }
  .dot {
    background-color: colors.$white !important;
  }
  
    }

    @media only screen and (max-width: 520px) {
  
      .container {
width: 250px !important;
      }
      
        }
        @media only screen and (max-width: 475px) {
  
          .container {
    width: 200px !important;
          }
          
            }

            @media only screen and (max-width: 410px) {
  
              .container {
        width: 130px !important; 
              }
              
                }
    @media only screen and (max-width: 350px) {
  
      .container {
width: 100px !important;
      }
      
        }