@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .imagePlaceholder {
    width: 170px;
    height: auto;
    border-radius: 50%;
    img {
      width: 100%;
      height: auto;
    }
  }

  .heading {
    color: colors.$black;
    text-align: center;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .subHeading {
    color: colors.$black;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    span {
      font-weight: 700;
      text-decoration-line: underline;
    }
  }

  button {
    width: 100%;
    border-radius: 10px;
    background-color: colors.$black;
    border: none;
    color: colors.$white;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    padding: 16px 0;
    box-sizing: border-box;
    cursor: pointer;
  }
}

@media only screen and (max-width: 768px) {
  .imagePlaceholder{
    display: none !important;
  }

  .heading {
    color: colors.$black;
    font-size: 24px !important;
    font-weight: 600;
  }
  
    }


