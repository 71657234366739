$black: #000;
$white: #fff;

$yellow: #FFF75E;
$red: #ff5454;
$grey-50: #f8f8fa;
$grey-300: #afafc1;
$grey-400: #8b8ba0;

$backgroundColor: #f9f9f9;
