@use "../../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 15px;

  .referralContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .imageNameContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 10px;
     

      .imagePlaceholder {
        width: 45px;
        height: 45px;
        border-radius: 50%;
        background-color: #d9d9d9;
      }

      .name {
        color: colors.$black;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 220px;
      }
    }

    button {
      background-color: colors.$black;
      border: none;
      border-radius: 10px;
      padding: 11px 25px;
      box-sizing: border-box;
      color: colors.$white;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }

  .statusesContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 5px;

    .statusContainer {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 5px;

      .statusBar {
        width: 90px;
        height: 10px;
        border-radius: 10px;
        background-color: colors.$white;

        &.selected {
          background-color: colors.$black;
        }
      }

      .status {
        color: colors.$black;
        font-family: Poppins;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}


@media only screen and (max-width: 768px) {

  .imagePlaceholder {
   display: none !important;
  }


}

@media only screen and (max-width: 450px) {
 
.nudge{
  padding: 6px 16px !important;
  font-size: 15px !important;
}
  
}

@media only screen and (max-width: 414px) {
 .name{
  font-size: 14px !important;
  width: 180px !important;
 }
  }
  @media only screen and (max-width: 350px) {
    .name{
     font-size: 14px !important;
     width: 130px !important;
    }
     }

// @media only screen and (max-width: 412px) {
// // .referralContainer {
// //   display: flex;
// //   flex-direction: column !important;
// //   align-items: center;
// // }
// }