@use "../../../../scss/colors";

@keyframes bounceIn {
  0% {
    opacity: 0;
    transform: translateX(100%);
  }
  60% {
    opacity: 1;
    transform: translateX(-10%);
  }
  80% {
    transform: translateX(5%);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.bounceIn {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: bounceIn;
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 150px 0;
  box-sizing: border-box;
  overflow-x: hidden; /* Added this line to prevent horizontal scroll */
.mobileButton {
  display: none;
}
  button {
    background-color: colors.$yellow;
    border: none;
    margin-top: -110px;
    margin-bottom: 80px;
    cursor: pointer;
    border-radius: 5px;
    padding: 15px 23px;
    color: colors.$black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  .innerContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;

    .leftContainer {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .headingLine {
        color: #fdb833;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
      }

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 36px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .contentContainer {
        border-left: 3px solid #ebebeb;
        cursor: pointer;

        &.selected {
          border-left: 3px solid #fff75e;
        }

        .heading {
          max-width: 400px;
          color: colors.$black;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          margin-left: 17px;
        }

        .text {
          max-width: 400px;
          color: colors.$black;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          margin-left: 17px;
          padding-bottom: 20px;
        }
      }
    }

    .rightContainer {
      display: flex;
      flex-direction: column;
      align-items: center;

      .productContainer {
        width: 100%; /* Changed from fixed width to 100% */
        max-width: 457px; /* Added max-width */
        height: auto;
        padding: 20px;
        box-sizing: border-box;
        border-radius: 10px;
        background-color: colors.$white;

        .productContentContainer {
          padding: 0;
          box-sizing: border-box;
          width: 140%; /* Changed from fixed width to 100% */
          max-width: 120%; /* Added max-width */
          display: flex;
          flex-direction: row;
          align-items: center;

          img {
            width: 100%;
            height: 340px;
          }

          .productContent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            .productName {
              max-width: 125px;
              color: colors.$black;
              font-size: 15px;
              font-style: normal;
              font-weight: 400;
              line-height: normal;
            }

            .productPrice {
              color: colors.$black;
              font-size: 40px;
              font-style: normal;
              font-weight: 600;
              line-height: normal;
            }

            button {
              width: 100%;
              border-radius: 20px;
              border: none;
              background-color: transparent;
              padding: 6px 0;
              color: colors.$black;
              font-size: 15px;
              font-style: normal;
              font-weight: 300;
              line-height: 24px;
              cursor: pointer;
            }

            .cartButton {
              background-color: #f7ca00;
            }

            .buyButton {
              background-color: #fa8900;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 800px) {
  .innerContainer {
    display: flex;
    flex-direction: column !important;
    justify-content: center !important;
    align-items: center !important;
  }
}

@media only screen and (max-width: 768px) {
  .innerContainer {
    display: flex;
    flex-direction: column !important;
  }

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    box-sizing: border-box;
    .mobileButton{
      display:flex;
      justify-content:center
    }
  }  

  .productContainer {
    margin-top: 30px !important;
    display: none;
  }
}

@media only screen and (min-width: 320px) and (max-width: 479px) {
  .productContainer {
    width: 330px !important;
  }

  .productContainer img {
    width: 123px !important;
    height: 115px !important;
  }
}

@media only screen and (max-width: 320px) {
  .productContainer {
    width: 230px !important;
  }

  .productContainer img {
    width: 50px !important;
    height: 50px !important;
    margin-left: -30px;
  }
}
