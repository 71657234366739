.pageContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  
    .formContainer {
      width: 80%;
      height: auto;
      border-radius: 10px;
      color: white;
      padding: 10px;
      margin: 10px 10px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
  
      .heading {
        color: #221F1F;
        text-align: center;
        font-family: Poppins;
        font-size: 29px;
        font-weight: 600;
  
        span {
          color: yellow;
        }
      }
  
      .fieldItem {
        width: 100%;
        height: auto;
      }
  
      .authButton {
        width: 100%;
        border-radius: 10px;
        border: none;
        padding: 15px 0;
        background-color: yellow;
        color: black;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        cursor: pointer;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
  
      .bottomText {
        color: white;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        span {
          color: yellow;
        }
        .link {
          text-decoration: none;
          cursor: pointer;
        }
      }
    }
  }

  .subHeading{
    color: #000;
font-family: Poppins;
font-size: 18px;
font-style: normal;
font-weight: 400;
span{
    color: #000;
    font-family: Poppins;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
}
  }

  .brands{
width: 100%;
height: 200px;
margin-top: 80px;
display: flex;
justify-content: center;
  }

  @media (max-width: 768px) {
    .brands{
        width: 100%;
        height: 200px;
        padding-top: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
          }
  }