@use "../../../scss/colors";

.container {
  width: 100%;
  border-radius: 5px 0 0 5px;
  padding: 16px 24px;
  box-sizing: border-box;
  background-color: transparent;
  color: colors.$black;
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration: none;

  &.isSelected {
    background-color: colors.$yellow;
  }
}
