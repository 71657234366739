@use "../../../scss/colors";
@use "../../../scss/variables";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .formContainer {
    width: 864px;
    height: auto;
    border-radius: 10px;
    // background-color: colors.$black;
    color: colors.$white;
    padding: 30px;
    margin: 90px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .heading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // letter-spacing: 3px;

      span {
        color: colors.$yellow;
      }
    }

    .fieldItem {
      width: 100%;
      height: auto;
    }

    .authButton {
      width: 70%;
      border-radius: 10px;
      border: none;
      padding: 15px 0;
      background-color: colors.$yellow;
      color: colors.$black;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .bottomText {
      color: colors.$black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: colors.$yellow;
      }
      .link {
        text-decoration: none;
        cursor: pointer;
      }
    }

    .bottomText2 {
      color: colors.$black;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      span {
        color: blue;
      }
      .link {
        text-decoration: none;
        cursor: pointer;
      }
    }

  }
}

.lineContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  .line {
    width: 100%;
    height: 3px;
    background-color: #cecece;
    border-radius: 2px;
  }
}

.li {
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}
.link {
  text-decoration: none;
  cursor: pointer;
  color: blue;
}


@media only screen and (max-width: 768px) {
  .fields{
    margin-top: -15px !important;
  }
  .fieldItem{
    margin-top: 10px !important;
  }
  .formContainer {
    margin-top: 15px !important;
      }

  .authButton{
    width: 100% !important;
  }

.fields{
  display: flex;
  flex-direction: column;
}
.fieldItem{
  margin-left: 0px !important; 
}
}


.customfield{
  max-width: none !important;
}