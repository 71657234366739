.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .imageSection {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      position: relative;

      .image {
        opacity: 10;
        position: absolute;
        margin-right: 50px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}

.mobileonly {
  display: none;

  @media only screen and (max-width: 600px) {
    display: block;
  }

  @media only screen and (max-width: 786px) {
    display: block;
  }

  img {
    width: 100%;
    height: auto;
    padding: 10px;
  }

  button {
    width: 200px;
    height: 80px;
    font-size: 22px;
    background-color: #fdb833; /* Example color, replace with your theme color */
    border: none;
    cursor: pointer;
    border-radius: 5px;
    color: #000000; /* Example color, replace with your theme color */
    font-family: "Poppins", sans-serif; /* Example font, replace with your preferred font */
    margin-top: 20px;

    img {
      margin-left: 10px;
      vertical-align: middle;
    }
  }
}

@media only screen and (max-width: 786px) {
  .container {
    display: none;
  }
}

@media only screen and (max-width: 360px) {
  .mobileonly img {
    width: 99%;
    padding-left: 15px;
  }
}
