@use "../../../../scss/colors";

.modal {
  background: colors.$yellow;
}

.header {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 10px;
  box-sizing: border-box;

  .heading {
    color: colors.$black;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  .itemContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 15px;

    .text {
      color: colors.$black;
      font-family: Poppins;
      font-size: 15px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
  }

  button {
    width: 100%;
    background-color: colors.$black;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    padding: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 10px;
    outline: none;

    .text {
      color: colors.$yellow;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }
}
