
@use "../../../../scss/colors";

@keyframes bounceIn {
  0% {
    transform: translateX(100%) scale(1.2);
    opacity: 0;
  }
  60% {
    transform: translateX(0) scale(1.2);
    opacity: 1;
  }
  100% {
    transform: translateX(0) scale(1.2);
    opacity: 1;
  }
}

@keyframes disappear {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  box-sizing: border-box;
  height: auto;

  .imageSec2 {
    display: none;
  }
  
  .hoverDiv {
    position: absolute;
    top: 100px;
    right: 55px;
    cursor: pointer;
    img {
      &.hoverCoin {
        transform: scale(5.2);
        margin-top: 150px;
        margin-right: 150px;
        right: 50px;
      }
      &.coinIcon {
        transform: scale(1.2);
        margin-top: 50px;
        margin-right: 30px;
        right: 50px;
      }
    }
    &.bounce {
      animation: bounceIn 1s forwards;
    }
    &.disappear {
      animation: disappear 1s forwards;
    }
  }
  
  
  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: auto;
    .imageSection {
      max-width: 470px;
      height: 100%;
      border-radius: 10px;
      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: center;
      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }
      .subHeading {
        max-width: 478px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }
      .text {
        max-width: 484px;
        color: colors.$black;
        font-family: Poppins;
        text-align: center;
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }
      button {
        background-color: colors.$yellow;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 23px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.showmobile {
  display: none !important;
}
.imageSec {
  display: flex;
  position: relative;
  padding-left: 60px;
}

@media only screen and (max-width: 768px) {
  .imageSec2 {
    display: flex;
  }
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    height: auto;
  }
  .showmobile {
    display: block !important;
    width: 100%;
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    .heading {
      color: colors.$white;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .subHeading {
      max-width: 478px;
      color: colors.$white;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }
    .text {
      max-width: 484px;
      color: colors.$white;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }
    button {
      background-color: colors.$yellow;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 23px;
      color: colors.$black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 530px) {
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    box-sizing: border-box;
    height: auto;
    .hoverDiv {
      position: absolute;
      right: 55px;
      cursor: pointer;
      img {
        height: 20px;
        width: 20px;
        margin-top: 10px;
        margin-left: 42px;
      }
      &.bounce {
        animation: bounceIn 1s forwards;
      }
      &.disappear {
        animation: disappear 1s forwards;
      }
    }
    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .heading {
        font-size: 22px !important;
      }
      .imageSec {
        display: none;
      }
      .imageSec2 {
        display: flex;
      }
      .subHeading {
        font-size: 30px !important;
      }
      .text {
        font-size: 24px !important;
      }
    }
  }
}
