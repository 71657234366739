@use "../../scss/colors";

.tabsContainer {
  // display: flex;
  // flex-direction: row;
  align-items: center;
  gap: 13px;
 

  .tab {
    border-radius: 5px;
    border: 1px solid colors.$black;
    background-color: colors.$white;
    padding: 9px 20px;
    box-sizing: border-box;
    color: colors.$black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;
    // margin-left: 10px;
    
    &.isSelected {
      border: 1px solid #fff200;
      background-color: #fff200;
    }
  }
}

.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .cardContainer {
    width: 470px;
    height: auto;
    padding: 52px 32px;
    box-sizing: border-box;
    background-color: colors.$yellow;
    border-radius: 10px;
  }
}

@media only screen and (max-width: 768px) {
.hidecontent{
  display: none !important;
}

.cardContainer {
  background-color: colors.$white !important;
  
}

  }

  @media only screen and (max-width: 474px) {
 
    .cardContainer {
      width: 350px !important;
    }
    .tab{
      padding: 6px 10px !important;
     }
      }

      @media only screen and (max-width: 440px) {
 
       .tab{
        padding: 6px 10px !important;
       }
        
          }


  @media only screen and (max-width: 413px) {
 
    .cardContainer {
      width: 300px !important;
    }
    .tab{
      padding: 6px 10px !important;
     }
    
      }



      @media only screen and (max-width: 400px) {
 
        .tab{
         padding: 6px 8px !important;
       
         font-size: 14px !important;
        }
         
           }

           @media only screen and (max-width: 375px) {
 
            .tab{
             padding: 6px 7px !important;
             font-size: 12px !important;
            }
             
               }

      @media only screen and (max-width: 350px) {
 
        .cardContainer {
          width: 250px !important;
        }
        
          }

          @media only screen and (max-width: 335px) {
 
            .tab{
             padding: 6px 7px !important;
             font-size: 12px !important;
             margin-left: 5px !important;
             margin-top: 5px !important;
            }
             
               }