@use "./../../scss/colors";
@use "./../../scss/variables";

.navbar {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: colors.$black;
  height: variables.$navbar;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;

  .container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .logoContainer {
      max-width: 150px;

      .logo {
        max-width: 150px;
        height: 100%;
      }
    }

    .navItemsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 32px;

      .navItem {
        color: colors.$white;
        text-align: center;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        text-transform: uppercase;
        text-decoration: none;
      }

      .selected {
        color: colors.$yellow;
      }

      button {
        background-color: colors.$yellow;
        border-radius: 5px;
        border: none;
        padding: 9px 16px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;
      }
    }
  }
}

.NavToggle {
  display: none;
  flex-direction: column;
  cursor: pointer;
  padding: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;

  .bar {
    width: 25px;
    height: 3px;
    background-color: white; /* Changed to white */
    margin: 3px 0;
  }
  .NavToggle svg {
    width: 25px;
    height: 25px;
  }
  
}



@media only screen and (max-width: 868px) {


  .navItemsContainer {
    gap: 15px !important;
    
    
    button {
      font-size: 14px !important;
    }

  .navItem {
    font-size: 14px !important;
  }
  }

}

.mobilehide{
 
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}
.additionalNavItems{
  display: none !important;
}

@media only screen and (max-width: 768px) {

  .mobilehide{
    display: none !important;
  }
  .additionalNavItems {
    display: flex !important;
    flex-direction: column;
    align-items: flex-start;
    gap: 15px;
    padding: 0 20px; // Adjust padding as needed
  }
  
  .navItemsContainer{
display: none !important;
  }

  .navItemsContainer.active{
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important; /* Align items vertically */
    position: absolute;
    top: 100% !important; /* Position below the navbar */
    left: 0 !important;
    
    width: 100%;
    background-color: colors.$black !important; /* Adjust background color */
    padding: 10px !important; /* Add padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important; /* Add box shadow */
    height: 100vh;
    clip: rect(0, 0, auto, 0);
    animation: slide 0.3s linear 0.3s forwards;
  }
  
.loggedIn {
    display: flex !important;
    flex-direction: column !important;
    align-items: flex-start !important; /* Align items vertically */
    position: absolute;
    top: 100% !important; /* Position below the navbar */
    left: 0 !important;
   
    
    width: 100%;
    background-color: colors.$white !important; /* Adjust background color */
    padding: 10px !important; /* Add padding */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2) !important; /* Add box shadow */
    height: 100vh;
    clip: rect(0, 0, auto, 0);
    animation: slide 0.3s linear 0.3s forwards;
  }

  .navItemsContainer.active .additionalNavItems {
    margin-left: -10px !important;
    background: rgb(255, 255, 255) !important;
    height: 100vh;
    }
 
  .additionalNavItems .navItem{
    color: colors.$black !important;
    margin-top: 15px;
    // background: yellow;
     text-align: left !important;
     width: 900px;
    height: 30px;
  }
  .creditContainer {
    display: flex;
    align-items: center;
  }
  .creditButton {
    background-color: colors.$yellow;
    border: none;
    cursor: pointer;
    border-radius: 5px;
  //   padding: 16px 38px;
  margin-top: 15px;
    color: #000;
    width: 18%;
    margin-left: 50px;
    font-family: Poppins;
    height: 40px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    box-shadow: -5px 4px #FFCE00;
  }

  .creditCircle {
    width: 120px;
    height: 120px;
    margin-left: 50px;
    margin-top: 30px;
    border: 4px solid black;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .creditText {
    color: black;
    font-size: 16px;
    text-align: center; 
    line-height: 1.5; 
  }  


@keyframes slide {
  100% { clip: rect(0, 100vw, auto, 0); }
}


  .NavToggle {
    display: flex !important; /* Display the toggle button */
  }

  .navbtn{
    margin-left: 10px;
  }
  .navItem {
    padding-left: 25px;
    padding-top: 5px;
  }
  
}