@use "../../../../../scss/colors";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  

.formContainer {
  width: 70%;
  height: auto;
  border-radius: 10px;
  // background-color: colors.$black;
  color: colors.$white;
  padding: 30px;
  margin: 90px 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
 
}

.heading {
  color: colors.$black;
  text-align: center;
  font-family: Poppins;
  // font-size: 21px;
  font-size: 30px;
  font-style: normal;
  // font-weight: 600;
  font-weight: 400;
  line-height: normal;
}

.text {
  color: colors.$black;
  text-align: center;
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

}

@media only screen and (max-width: 500px) {
br{
  display: none !important;
}
  .heading{
 font-size: 26px !important;
  }
  .text{
    font-size: 16px !important;
  }

}
