.lineContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 2px;
  
    .line {
      width: 100%;
      height: 3px;
      background-color: #cecece;
      border-radius: 2px;
    }
  }
  
  .li {
    color: black;
    font-family: Poppins;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  
  .text {
    font-size: 14px;
    color: white;
    margin: 5px 0;
    padding: 0;
  }
  
  .google_btn {
    width: 100%;
    height: 40px;
    border-radius: 5px;
    border: none;
    outline: none;
    background-color: #f9f9f9;
    border: 1px solid black;
    font-size: 16px;
    font-weight: 500;
    margin: 0 0 20px 0;
    color: #2c444e;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }
  
  .google_btn > img{
    width: 30px;
    height: 30px;
    padding-right: 20px;
    object-fit: cover;
  }
  
  .google_btn > span{
    margin-left: 10px;
  }
  
  .hr_lines:before{
    content:" ";
    display: block;
    height: 0.5px;
    width: 140px;
    position: absolute;
    top: 50%;
  margin-left: 60px;
    left: 0;
    background: #3a393d;
  }
  
  .hr_lines{
    position: relative;
    max-width: 500px;
    margin: 10px auto;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: center;
    font-size: 15px;
    font-family: Poppins;
    font-weight: 400;
    color: #3a393d;
  }
  
  .hr_lines:after{
    content:" ";
    height: 0.5px;
    width: 140px;
    background: #3a393d;
    display: block;
    position: absolute;
    top: 50%;
  margin-right: 60px;
    right: 0;
  }
  .customfield{
    max-width: none !important;
  }