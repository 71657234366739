@use "../../scss/colors";

.footer {
  width: 100%;
  background-color: colors.$yellow;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .container {
    max-width: 700px;
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    .footerContent {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      justify-content: space-between;
      margin-bottom: 70px;

      .footerList {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .heading {
          color: #000;
          font-family: Poppins;
          font-size: 18px;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          text-transform: uppercase;
        }

        .footerListItem {
          color: #000;
          font-family: Poppins;
          padding-top: 20px;
          font-size: 18px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          text-decoration: none;
        }
      }
    }

    .footerRightText {
      color: #000;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 768px) {
  .footerContent {
    
    display: flex;
    flex-direction: column !important;
  }

  .footerList {
padding-left: 10px;
margin-top:20px;
  }
}
