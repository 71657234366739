@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 52px;
  box-sizing: border-box;
  height: auto;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: auto;

    .imageSection {
      max-width: 470px;
      height: 100%;
      border-radius: 10px;

      img {
        width: 100%;
        height: auto;
        border-radius: 10px;
      }
    }

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .subHeading {
        max-width: 478px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }

      .text {
        max-width: 484px;
        color: colors.$black;
        font-family: Poppins;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      button {
        background-color: colors.$yellow;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 23px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.showmobile{
  display: none !important;
}



@media only screen and (max-width: 768px) {

  .container {
    display: none !important;
  }
  .showmobile{
    display: block !important;
    width: 100%;
    margin-top: -80px;
  }

  .content {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .heading {
      color: colors.$black;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .subHeading {
      max-width: 478px;
      color: colors.$black;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: 40px;
    }

    .text {
      max-width: 484px;
      color: colors.$black;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    button {
      background-color: colors.$yellow;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      // padding: 12px;
      color: colors.$black;
      font-family: Poppins;
      width: 200px;
      height: 60px;
      font-size: 16px;
      margin-right: 15px;
      font-style: normal;
      font-weight: 400;
      // line-height: normal;
    }
  }
  
 
}




@media only screen and (max-width: 530px) {
 

  .content {
    margin-top: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
padding-left: 20px;

    .heading {
      font-size: 32px !important;
    }

    .subHeading {
      font-size: 20px !important;
    }

    .text {
      font-size: 16px !important;
    }
  }
  
 
}