@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  .heading {
    color: colors.$black;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .lineSeparator {
    width: 100%;
    border-top: 1px solid colors.$black;
  }

  .dotLineSeparator {
    width: 100%;
    border-top: 1px dashed rgba(0, 0, 0, 0.25);
  }

  .formItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 10px;

    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      input {
        width: 100%;
        border-radius: 10px;
        border: 1px solid #000;
        background-color: colors.$white;
        color: rgba(0, 0, 0, 0.5);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px;
        box-sizing: border-box;
      }

      .error {
        color: red;
        font-size: 11px;
        margin-top: 5px;
        margin-left: 15px;
      }
    }

    button {
      background-color: colors.$black;
      border-radius: 10px;
      padding: 12.5px 24px;
      box-sizing: border-box;
      border: none;
      color: colors.$white;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }

  .addContainer {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .text {
      color: colors.$black;
      font-family: Poppins;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
