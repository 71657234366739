@use "../../../../scss/colors";

.collapsibleContainer {
  width: 100%;
  border-radius: 5px;
  padding: 19px 30px;
  box-sizing: border-box;
  cursor: pointer;

  .textContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;

    .text {
      color: colors.$black;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  .collapsible {
    overflow: hidden;
    color: colors.$black;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    user-select: none;
    max-height: 0;
    // transition: max-height 0.01s ease;

    &.open {
      max-height: 500px;
      padding-top: 15px;
    }
  }
}
