@use "../../scss/colors";
@use "../../scss/variables";

.container {
    width: variables.$sidebar-width;
    height: 100%;
    position: fixed;
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .container {
       display: none !important;
       width: none !important;
    }

}

.creditButton {
      background-color: colors.$yellow;
      border: none;
      cursor: pointer;
      border-radius: 5px;
    //   padding: 16px 38px;
    margin-top: 15px;
      color: #000;
      width: 60%;
      margin-left: 50px;
      font-family: Poppins;
      height: 40px;
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      box-shadow: -5px 4px #FFCE00;
    }

    .creditContainer {
      display: flex;
      align-items: center;
    }
    
    .creditCircle {
      width: 120px;
      height: 120px;
      margin-left: 50px;
      margin-top: 30px;
      border: 4px solid black;
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    
    .creditText {
      color: black;
      font-size: 16px;
      text-align: center; /* Center text horizontally */
      line-height: 1.5; /* Set line height to ensure text is centered vertically */
    }
    
    /* Adjust font size for smaller screens */
    @media (max-width: 768px) {
      .creditCircle {
        width: 80px;
        height: 80px;
        margin-left: 20px;
        margin-top: 15px;
      }
    
      .creditText {
        font-size: 12px;
      }
    }
    