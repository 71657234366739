@use "../../../../scss/colors";

.container {
  width: 100%;
  height: auto;
  border-radius: 5px;
  background-color: colors.$white;
  padding: 32px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;


  .header {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .heading {
      color: colors.$black;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .buttonsContainer {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-end;
      gap: 20px;

      button {
        border-radius: 5px;
        background-color: #fff200;
        border: 1px solid #fff200;
        padding: 8px 21px;
        box-sizing: inherit;
        color: colors.$black;
        text-align: right;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        cursor: pointer;

        &.outlined {
          background-color: transparent;
          border: 1px solid colors.$black;
          padding-right: 20px;
        }
        
      }
    }
  }

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    
    .dataContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .inputFile {
        display: none;
      }

      .imageContainer {
        min-width: 167px;
        min-height: 167px;
        border-radius: 50%;
        background-color: #888888;
        .updateImage {
          position: absolute;
          z-index: 2;
          border-radius: 10px;
          padding: 5px;
          background-color: #8888889c;
        }
        .image {
          min-width: 167px;
          max-width: 167px;
          min-height: 167px;
          max-height: 167px;
          border-radius: 50%;
        }

        &.imagePlaceholder {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          color: colors.$white;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;

          &.imageSelection {
            cursor: pointer;
          }
        }
      }

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .data {
        color: colors.$black;
        font-family: Poppins;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      input {
        min-width: 250px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid colors.$black;
        padding: 8px 12px;
        box-sizing: border-box;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:focus {
          outline: none;
        }
      }

      .error {
        color: red;
        font-size: 11px;
      }
    }
  }
}

.showmobile{
  display: none;
}

.showonmobile{
  display: none;
}

.buttonsContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;

  button {
    border-radius: 5px;
    background-color: #fff200;
    border: 1px solid #fff200;
    padding: 8px 21px;
    box-sizing: inherit;
    color: colors.$black;
    text-align: right;
    font-family: Poppins;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    cursor: pointer;

    &.outlined {
      background-color: transparent;
      border: 1px solid colors.$black;
    }
  }
}


.showonfull button{
 margin-left: 10px;
}

@media only screen and (max-width: 915px) {
  
  
  .contentContainer {
    display: flex;
flex-direction: column !important; 
  .dataContainer {
display: flex;
flex-direction: column;  
}


}
}

@media only screen and (max-width: 768px) {

  .container {
  margin-bottom:-28px !important;
  }
  
  .showonmobile{
    display: block;
    margin-top: 20px;
  }
 
  .showonfull{
    display: none !important;
  }
  .contentContainer {
    display: flex;
flex-direction: column !important; 
  .dataContainer {
display: flex;
flex-direction: column;  
}

.heading{
  font-size: 18px;
}

}
}
@media only screen and (max-width: 500px) {
  .showmobile{
    display: flex;
  }
  .showfull{
    display: none !important;
  }
}

@media only screen and (max-width: 430px) {
  .container {
    padding: 12px;
  }
  
}

@media only screen and (max-width: 356px) {
  .data {
    
    font-size: 12px !important;
  }
}
