@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;

  .text {
    color: colors.$black;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;

    &.data {
      font-size: 16px;
    }
  }

  .card {
    width: 100%;
    border-radius: 5px;
    background-color: colors.$white;
    padding: 32px;
    box-sizing: border-box;
    margin-bottom: -30px;

    .header {
      width: 100%;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      .buttonsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        gap: 20px;

        button {
          border-radius: 5px;
          background-color: #fff200;
          border: 1px solid #fff200;
          padding: 8px 21px;
          box-sizing: inherit;
          color: colors.$black;
          text-align: right;
          font-family: Poppins;
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          cursor: pointer;

          &.outlined {
            background-color: transparent;
            border: 1px solid colors.$black;
          }
        }
      }
    }

    .dataContainer {
      width: 100%;
      display: flex;
      flex-direction: row;

      input {
        min-width: 250px;
        border-radius: 5px;
        background-color: transparent;
        border: 1px solid colors.$black;
        padding: 8px 12px;
        box-sizing: border-box;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        &:focus {
          outline: none;
        }
      }
    }

    .column {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    .row {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;
    }
  }
}


@media only screen and (max-width: 768px) {
  
  .container {
display: none !important;


  }
   
}