$navbar: 70px;
$sidebar-width: 227px;

$maxwidth: 980px;

$spacing-xs: 3px;
$spacing-sm: 5px;
$spacing-md: 10px;
$spacing-lg: 15px;
$spacing-xl: 20px;
$spacing-2xl: 40px;

$width-size-menu: 93px;

$width-modal-sm: 464px;
$width-modal-md: 650px;
$width-modal-lg: 940px;
$width-modal-xl: 1008px;