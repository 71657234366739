@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  .backBtn {
    position: absolute;
    cursor: pointer;
    top: -74px;
    left: -24px;
    background-color: transparent;
    padding: 0;
    outline: none;
    border-radius: 5px;
    border: none;
  }

  .heading {
    color: colors.$black;
    text-align: center;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    max-width: 280px;
  }

  .formItem {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;

    .inputContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      // align-items: flex-start;

      input {
        width: 100%;
        border: none;
        border-radius: 10px;
        background-color: colors.$white;
        color: rgba(0, 0, 0, 0.5);
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        padding: 15px;
        box-sizing: border-box;
      }

      .error {
        color: red;
        font-size: 11px;
        margin-top: 5px;
        margin-left: 15px;
      }
    }

    button {
      width: 100%;
      background-color: colors.$black;
      border-radius: 10px;
      padding: 12.5px 24px;
      box-sizing: border-box;
      border: none;
      color: colors.$white;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
    }
  }
}

.mobileonly{
  display: none !important;
}


@media only screen and (max-width: 768px) {

  .mobileonly{
    display: block !important;
  }
  
  .invitebtn{
    margin-top: 10px;
  }

  .heading {
    max-width: none !important;
    margin-top: -25px;
  }

  .hidecontent{
    display: none !important;
  }
  
  .formItem {
    display: flex;
    flex-direction: column !important;
  }

 
  .inputContainer input {
    border: 1px solid rgba(0, 0, 0, 1) !important;
    
  }
  
    }

    @media only screen and (max-width: 420px) {
  
      
      
      
        }