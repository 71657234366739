@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px 0;
  box-sizing: border-box;

  .imageSection {
    width: 100%;
    height: 600px;
    border-radius: 10px;
    // background: colors.$black url("../../../../resources/images/MaskGroup.png")
      // no-repeat center;
    background-size: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    button {
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 20px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      background-color: colors.$yellow;
      border-radius: 10px;
      border: none;
      cursor: pointer;
      padding: 10px 56px;
      box-shadow: -5px 4px #FFCE00;
    }
  }
}

.mobileonly{
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .hidecontent{
    display: none !important;
  }
  .mobileonly{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
}


@media only screen and (max-width: 360px) {
  .mobileonly img{
  width: 99% !important;
  padding-left: 15px;
 }
  
  }