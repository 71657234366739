@use "../../scss/colors";

.container {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .inputContainer {
    width: 100%;
    max-width: 458px;
    padding: 9px;
    box-sizing: inherit;
    border-radius: 5px;
    background-color: #f8f8f8;
    border: none;
    display: flex;
    flex-direction: row;
    align-items: center;

    input {
      margin-left: 5px;
      width: 100%;
      border: none;
      background-color: transparent;
      color: rgba(0, 0, 0, 0.27);
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;

      &:focus {
        outline: none;
      }
    }
  }

  .heading {
    color: colors.$black;
    font-family: Poppins;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  .faqsContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .messageUsContainer {
    width: 100%;
    padding: 10px 14px 22px 14px;
    box-sizing: border-box;
    border-radius: 5px;
    background-color: #e8e8e8;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .headline {
      color: colors.$black;
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }

    .text {
      color: colors.$black;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}
