@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px 0 104px 0;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: colors.$black;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .subHeading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      // line-height: 40px;
    }

    button {
      background-color: colors.$yellow;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 18px;
      color: #000;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      // box-shadow: -5px 4px #FFCE00;
    }
  }
}


@media only screen and (max-width: 530px) {
 
  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 52px 0 104px 0;
    box-sizing: border-box;
  
    .contentContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
  
      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 28px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
  
      .subHeading {
        color: colors.$black;
        text-align: center;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        // line-height: 40px;
      }
  
      button {
        background-color: colors.$yellow;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 18px;
        color: #000;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        // box-shadow: -5px 4px #FFCE00;
      }
    }
  }
}