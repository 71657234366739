@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px 0 0 0;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: left;

    .heading {
      color: colors.$black;
      color: #000;
      text-align: center;
      font-family: Poppins;
      font-size: 40px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .subText{
      color: #000;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
    }
  

    .pricingContainer {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 24px;
    }
  }
}
