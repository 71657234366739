@use "../../scss/colors";
@use "../../scss/variables";

.pageContainer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
 

  .formContainer {
    width: 464px;
    height: auto;
    border-radius: 10px;
    // background-color: colors.$black;
    color: colors.$white;
    padding: 30px;
    margin: 90px 30px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    .heading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 24px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      //letter-spacing: 3px;

      span {
        color: colors.$yellow;
      }
    }

    .fieldItem {
      width: 100%;
      height: auto;
    }

    .authButton {
      width: 100%;
      border-radius: 10px;
      border: none;
      padding: 15px 0;
      background-color: colors.$yellow;
      color: colors.$black;
      font-family: Poppins;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      cursor: pointer;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }

    .bottomText {
      color: colors.$black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      span {
        color: colors.$yellow;
      }
      .link {
        text-decoration: none;
        cursor: pointer;
      }
    }

    .bottomText2 {
      color: colors.$black;
      font-family: Poppins;
      font-size: 12px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
      span {
        color: blue;
      }
      .link {
        text-decoration: none;
        cursor: pointer;
      }
    }

  }
}

.lineContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2px;

  .line {
    width: 100%;
    height: 3px;
    background-color: #cecece;
    border-radius: 2px;
  }
}

.li {
  // color: rgba(255, 255, 255, 0.5);
  color:  rgba(0, 0, 0, 1);
  font-size: 12px;  
  font-style: normal;
  font-weight: 400;
  // line-height: 24px;
  line-height: 6px;
}
.link {
  text-decoration: none;
  cursor: pointer;
  color: blue;
}

.customfield{
  max-width: none !important;
}

.customfield1{
  max-width: none !important;
}

@media only screen and (max-width: 768px) {
  .formContainer {
    margin-top: 20px !important;
      }

  .customfield{
    max-width: none !important;
    margin-top: 16px !important;
  }
  
.customfield1{
  max-width: none !important;
}


}

  @media only screen and (max-width: 435px) {

    .customfield1{
      max-width: none !important;
    }
    
    .customfield1::placeholder {
     font-size: 14px;
    }
    
    }

    @media only screen and (max-width: 375px) {

      .customfield1{
        max-width:  auto !important;
      }
      
      .customfield1::placeholder {
       font-size: 11px;
      }
      
      }