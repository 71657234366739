@use "../../scss/colors";

.container {
    width: 100%;
    display: flex;
    flex-direction: column;

    .tabsContainer {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 13px;
    
        .tab {
            border-radius: 5px;
            border: 1px solid colors.$black;
            padding: 9px 20px;
            box-sizing: border-box;
            color: colors.$black;
            text-align: center;
            font-size: 15px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            cursor: pointer;
    
            &.isSelected {
                border: 1px solid #B3B3B3;
                background-color: #B3B3B3;
            }
        }
    }
}
