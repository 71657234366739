@use "../../scss/colors";

.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    .innerContainer {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 40px 0 200px 0;
        box-sizing: border-box;

        .heading {
            color: colors.$black;
            font-family: Poppins;
            font-size: 40px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
        }

        .subHeading {
            color: colors.$black;
            font-family: Poppins;
            font-size: 15px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .stepsContainer {
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 16px;

                .stepContainer {
                    width: 100%;
                    display: flex;
                    flex-direction: row;
                    align-items: flex-start;
                    gap: 24px;

                    .numberContainer {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        gap: 16px;

                        .stepNumber {
                            color: rgba(0, 0, 0, 0.50);
                            font-family: Poppins;
                            font-size: 24px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            width: 60px;
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: center;
                            background-color: transparent;
        
                            &.isSelected {
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                background-color: colors.$yellow;
                                color: colors.$black;
                            }
                        }
        
                        .stepSeperator {
                            width: 2px;
                            height: 50px;
                            background-color: #7D7D7D;
        
                            &.isSelected {
                                height: 95px;
                            }
                        }
                    }

                    .descriptionContainer {
                        display: flex;
                        flex-direction: column;
                        align-items: flex-start;

                        .heading {
                            color: colors.$black;
                            font-family: Poppins;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .description {
                            max-width: 420px;
                            color: colors.$black;
                            font-family: Poppins;
                            font-size: 15px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        button {
                            border-radius: 10px;
                            background-color: colors.$yellow;
                            border: none;
                            padding: 10px 28px;
                            box-sizing: border-box;
                            cursor: pointer;
                            color: colors.$black;
                            font-family: Poppins;
                            font-size: 20px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }
                    }
                }

        }
    }
}