@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 52px 0;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: #fdb833;
      font-family: Poppins;
      font-size: 22px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .subHeading {
      color: colors.$black;
      text-align: center;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .imageSection {
      max-width: 980px;
      height: 100%;
      border-radius: 10px;

      img {
        width: 100%;
        height: 100%;
        // margin-top: -50px;
        border-radius: 10px;
      }
    }
  }
}

.mobileonly{
  display:none !important
}


@media only screen and (max-width: 600px) {
  .hidecontent{
    display: none !important;
  }
  .mobileonly{
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  
  }

  @media only screen and (max-width: 360px) {
    .mobileonly img{
    width: 99% !important;
    padding-left: 15px;
   }
    
    }