@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px 0;
  box-sizing: border-box;
  height: auto;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 24px;
    height: auto;

    .content {
      display: flex;
      flex-direction: column;
      align-items: center;

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .text {
        max-width: 484px;
        color: colors.$black;
        font-family: Poppins;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 300;
        line-height: normal;
      }

      button {
        background-color: #0C9B00;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 23px;
        color: white;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

.stickyButton {
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
}

.showmobile {
  display: none !important;
}

@media only screen and (max-width: 768px) {
  .container {
    display: none !important;
  }
  .showmobile {
    display: block !important;
    width: 100%;
  }

  .content {
    display: flex;
    flex-direction: column;
    align-items: center;

    .heading {
      color: colors.$black;
      font-family: Poppins;
      font-size: 42px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .text {
      max-width: 484px;
      color: colors.$black;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 300;
      line-height: normal;
    }

    button {
      background-color: #0C9B00;
      border: none;
      cursor: pointer;
      border-radius: 5px;
      padding: 10px 23px;
      color: colors.$white;
      font-family: Poppins;
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

@media only screen and (max-width: 530px) {
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 20px;
    margin-top: 50px;

    .heading {
      font-size: 28px !important;
      font-weight: 400;
    }

    .text {
      font-size: 13px !important;
      text-align: center;
      padding-bottom: 50px;
    }
  }
}
