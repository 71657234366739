@use "../../scss/colors";

@keyframes spin {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(359deg);
  }
}

.spinner {
  width: 42px;
  height: 42px;
  border-width: 4px;
  border-radius: 50%;
  animation: spin 1.5s linear 0s infinite;
}

.small {
  width: 30px;
  height: 30px;
  border-width: 3px;
}

.extraSmall {
  width: 12px;
  height: 12px;
  border-width: 2px;
}