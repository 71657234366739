@use "../../../../scss/colors";

.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 52px 0;
  box-sizing: border-box;

  .contentContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding-left: 70px;

    .content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;

      .heading {
        color: colors.$black;
        font-family: Poppins;
        font-size: 42px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
      }

      .subHeading {
        max-width: 478px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 24px;
        font-style: normal;
        font-weight: 400;
        line-height: 40px;
      }

      .text {
        max-width: 884px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .subText {
        max-width: 884px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tableText2 {
        max-width: 884px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
      .tableText1 {
        color: colors.$black;
        font-family: Poppins;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }

      button {
        background-color: colors.$yellow;
        border: none;
        cursor: pointer;
        border-radius: 5px;
        padding: 10px 23px;
        color: colors.$black;
        font-family: Poppins;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }
}

table {
    width: 100%;
    margin-top: 10px;
    border-collapse: collapse;
}

td {
    border: 1px solid black;
    padding: 10px;
    text-align: left;
}

td:first-child {
    width: 200px; 
}



@media only screen and (max-width: 768px) {
  .contentContainer {
   
padding-left: 0px !important;
   
  }

  td {
    border: 1px solid black;
    padding: 5px !important;
    text-align: left;
}


}